<template>
    <div class='applicationFormchang'>
        <el-steps :active="step" align-center style='width:100%;margin:12px 0 24px 0;'>
            <el-step title="应用信息"></el-step>
            <el-step title="交付说明"></el-step>
            <el-step title="售后服务"></el-step>
        </el-steps>
        <div class='borderCard flex-center'>
            <div class='card-center'>
                    <div v-show='step == 1'>
                        <el-form ref="appInfoForm" label-position="right" :rules='rules' :model="appInfoForm" label-width="140px">
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="应用名称：" prop="name">
                                        <el-input v-model='appInfoForm.name' placeholder='请输入应用名称'></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="应用类别：" prop="appType">
                                        <el-select v-model="appInfoForm.appType" placeholder="请选择应用类别">
                                            <el-option v-for='item in appTypeList' :key='item.index' :label="item.name" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="应用场景标签：" prop="sceneTags">
                                        <el-select v-model="appInfoForm.sceneTags" multiple :multiple-limit='3' placeholder="请选择应用场景标签（最多3个）">
                                            <el-option v-for='item in sceneList' :key='item.index' :label="item.name" :value="item.value"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="应用简介：" prop="introduce">
                                        <el-input type="textarea" v-model='appInfoForm.introduce' maxlength="500" show-word-limit :autosize="{ minRows: 3, maxRows: 8}" placeholder='请输入应用简介'></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="配图：" prop="illustration">
                                        <el-upload
                                            class="avatar-uploader"
                                            action=""
                                            :show-file-list="false"
                                            :http-request='uploadFile'
                                            :on-change='changeFile'
                                            :on-success="handleAvatarSuccess"
                                        >
                                                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="宣传图：" prop="licenseId">
                                        <el-upload
                                            class="avatar-uploader"
                                            action=""
                                            :show-file-list="false"
                                            :http-request='uploadMapFile'
                                            :on-change='changeMapFile'
                                            :on-success="handleAvatarMapSuccess"
                                        >
                                            <img v-if="imageMapUrl" :src="imageMapUrl" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="应用地址：" prop="licenseId">
                                            <el-row>
                                                <el-input style='width:500px;margin-bottom:8px;' v-model='appInfoForm.appDetailAddVo.url' placeholder='请输入完整地址 例：https://www.baidu.com/'></el-input>
                                            </el-row>
                                            <el-row v-for='(item,index) in passList' :key='item.index'>
                                                <el-input style='width:220px;margin-right:8px' v-model='item.account' placeholder='请输入体验账号'></el-input>
                                                <el-input style='width:220px;margin-right:8px' v-model='item.password' placeholder='请输入体验密码'></el-input>
                                                <el-button icon='el-icon-plus' v-if='index == passList.length - 1' type='primary' @click='addAccount'></el-button>
                                            </el-row>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="应用亮点：" prop="appDetailAddVo.lightSpot">
                                        <el-input type="textarea" v-model='appInfoForm.appDetailAddVo.lightSpot' maxlength="500" show-word-limit :autosize="{ minRows: 3, maxRows: 8}" placeholder='请详细描述应用亮点优势'></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="应用说明：" prop="appDetailAddVo.appExplain">
                                        <el-input type="textarea" v-model='appInfoForm.appDetailAddVo.appExplain' maxlength="500" show-word-limit :autosize="{ minRows: 3, maxRows: 8}" placeholder='请详细描述应用详细说明'></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="应用场景：" prop="appDetailAddVo.scene">
                                        <el-input type="textarea" v-model='appInfoForm.appDetailAddVo.scene' maxlength="500" show-word-limit :autosize="{ minRows: 3, maxRows: 8}" placeholder='请详细描述应用场景'></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="应用文档：" prop="licenseId">
                                        <el-upload
                                            class="upload-demo"
                                            action=""
                                            :limit="1"
                                            :http-request='uploadFiles'
                                        >
                                            <el-button size="small" type="primary">点击上传</el-button>
                                            <div slot="tip" class="el-upload__tip">支持扩展名：.rar .zip .doc .docx .pdf .jpg</div>
                                        </el-upload>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                    <div v-show='step == 2'>
                        <el-form ref="specInfoForm" label-position="right" :rules='rules' :model="specInfoForm" label-width="140px">
                            <div v-for='item in specInfoForm.specList' :key='item.index'>
                                <el-row>
                                    <el-col :span='span'>
                                        <el-form-item label="规格名称：" prop="name" :rules="[{ required: true, message: '请输入规格名称', trigger: 'blur',validator: validateName(item.name) },]">
                                            <el-input style='width:500px;' v-model='item.name' placeholder='请输入规格名称'></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span='span'>
                                        <el-form-item label="规格说明：" prop="deliveryExplain" :rules="[{ required: true, message: '请输入规格说明', trigger: 'blur',validator: validateDeliveryExplain(item.deliveryExplain) },]">
                                            <el-input style='width:500px;' v-model='item.deliveryExplain' placeholder='请输入规格说明'></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                            <el-row>
                                <el-button style='position:relative;left:140px;margin-bottom:16px;' icon='el-icon-plus' type='primary' @click='addSpecifications'>添加规格</el-button>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="交付类型：" prop="deliveryTypes">
                                        <el-checkbox-group v-model="specInfoForm.deliveryTypes">
                                            <el-checkbox v-for='item in deliveryTypeList' :key='item.index' :label="item.value">{{ item.name }}</el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="交付时间：" prop="deliveryDays">
                                        <el-radio-group v-model="specInfoForm.deliveryDayType">
                                            <el-radio :label="0">
                                                <el-input-number style='width:64px'  v-model.trim='specInfoForm.deliveryDays' :disabled='specInfoForm.deliveryDayType == 1' controls-position="right" :controls='false' :min='1'></el-input-number> 日 内交付
                                            </el-radio>
                                            <el-radio :label="1">按需确认交付时间</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="单位：" prop="deliveryUnit">
                                        <el-select style='width:160px' v-model="specInfoForm.deliveryUnit" placeholder='请选择单位'>
                                            <el-option v-for='item in unitList' :key='item.index' :label='item.name' :value='item.value'></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="含税价格：" prop="taxPrice">
                                        <el-input style='width:160px'  v-model="specInfoForm.taxPrice" placeholder='请输入'></el-input> 元
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                    <div v-show='step == 3'>
                        <el-form ref="afterSalesForm" label-position="right" :rules='rules' :model="afterSalesForm" label-width="140px">
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="质保期限：" prop="warrantyPeriod">
                                        <el-row>
                                            <el-input style='width:200px;margin-right:12px;' v-model="afterSalesForm.warrantyPeriod" placeholder='请输入'></el-input>
                                            <el-select style='width:100px' v-model="afterSalesForm.warrantyType" placeholder='请选择'>
                                                <el-option label='年' :value='1'></el-option>
                                                <el-option label='月' :value='2'></el-option>
                                                <el-option label='日' :value='3'></el-option>
                                            </el-select>
                                        </el-row>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="保障说明：" prop="explains">
                                        <el-input type="textarea" v-model='afterSalesForm.explains' maxlength="500" show-word-limit :autosize="{ minRows: 3, maxRows: 8}" placeholder='请输入质保内容、售后服务、保修条款、退款条款等内容'></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span='span'>
                                    <el-form-item label="发票类型：" prop="invoiceType">
                                        <el-radio-group v-model="afterSalesForm.invoiceType">
                                            <el-radio :label="0">增值税专用发票</el-radio>
                                            <el-radio :label="1">增值税普通发票</el-radio>
                                            <el-radio :label="2">两者都支持</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                <div style='text-align:center;margin:16px 0;'>
                    <el-button @click='cancel' icon='el-icon-circle-close' v-if='step == 1'> 取 消 </el-button>
                    <el-button @click='back' icon='el-icon-back' v-if='step != 1'> 上一步 </el-button>
                    <el-button @click='next' icon='el-icon-right' type='primary' v-if='step != 3'> 下一步 </el-button>
                    <el-button @click='submitForm' type='primary' icon='el-icon-circle-check' v-if='step == 3'> 提 交 </el-button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import api from '@/api/api'
export default {
    data(){
        return{
            step:1,
            span:22,
            imageUrl:'',
            imageMapUrl:'',

            specInfoForm:{
                deliveryDayType:0,
                specList:[
                    { name:'', deliveryExplain:'' }
                ],
                deliveryTypes:[],
                appId:'',
                id:'',
            },
            appInfoForm:{
                appDetailAddVo:{
                },
                sceneTags:[],
                appId:'',
            },
            afterSalesForm:{
                warrantyType:2
            },
            passList:[
                {  account:'', password:'' },
            ],
            rules:{
                name: [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                appType: [
                    { required: true, message: '请填写必填项', trigger: 'change' },
                ],
                sceneTags: [
                    { required: true, message: '请填写必填项', trigger: 'change' },
                ],
                introduce: [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                illustration: [
                    { required: false, message: '请填写必填项', trigger: 'blur' },
                ],
                'appDetailAddVo.lightSpot': [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                'appDetailAddVo.appExplain': [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                'appDetailAddVo.scene': [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                deliveryTypes: [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                deliveryDays: [
                    { required: true, message: '请填写必填项', trigger: 'blur',validator: this.validateDeliveryDays  },
                ],
                deliveryUnit: [
                    { required: true, message: '请填写必填项', trigger: 'change' },
                ],
                warrantyPeriod: [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
            },
            sceneList:[],
            appTypeList:[],
            deliveryTypeList:[],
            unitList:[],
        }
    },
    created(){
        this.queryDict()
        if(this.$route.query.id){
            this.queryAppDetail(this.$route.query.id)
        }
    },
    methods:{
        queryAppDetail(id){
            api.queryGateWayAppDetail(this.$route.query.id).then(res=>{
                if(res.code == 200){
                    this.passList = res.data.appDetailAddVo.passwords
                    this.appInfoForm = {
                        appId:this.$route.query.id,
                        name: res.data.name,
                        appType: res.data.appType + '',
                        sceneTags: res.data.sceneTags,
                        introduce: res.data.introduce,
                        appDetailAddVo:res.data.appDetailAddVo,
                    }
                    this.specInfoForm = {
                        deliveryDays:res.data.appSpecAddVos[0].deliveryDays,
                        deliveryDayType:res.data.appSpecAddVos[0].deliveryDays == -1 ? 1 : 0,
                        specList:[
                            { name:'', deliveryExplain:'' }
                        ],
                        deliveryTypes:[],
                        deliveryUnit:'',
                        taxPrice:'',
                        id:res.data.appSpecAddVos[0].id,
                        appId:this.$route.query.id,
                    },
                    this.specInfoForm.specList = res.data.appSpecAddVos.map(item => {
                        return {
                            name:item.name,
                            deliveryExplain:item.deliveryExplain
                        }
                    })
                    this.specInfoForm.deliveryTypes = res.data.appSpecAddVos[0].deliveryTypes
                    this.specInfoForm.deliveryUnit = res.data.appSpecAddVos[0].deliveryUnit + ''
                    this.specInfoForm.taxPrice = res.data.appSpecAddVos[0].taxPrice

                    this.afterSalesForm = {
                        warrantyPeriod:res.data.warrantyPeriod,
                        warrantyType:res.data.warrantyType,
                        explains:res.data.explains,
                        invoiceType:res.data.invoiceType,
                    }
                }
            })
        },
        queryDict(){
            this.queryDictSence()
            this.queryDictAppType()
            this.queryDictDeliveryType()
            this.queryDictUnit()
        },
        queryDictSence(){
            api.queryDictDetail('scene_tag').then(res => {
                if(res.code == 200){
                    this.sceneList = res.data
                }
            })
        },
        queryDictAppType(){
            api.queryDictDetail('app_type').then(res => {
                if(res.code == 200){
                    this.appTypeList = res.data
                }
            })
        },
        queryDictDeliveryType(){
            api.queryDictDetail('delivery_type').then(res => {
                if(res.code == 200){
                    this.deliveryTypeList = res.data
                }
            })
        },
        queryDictUnit(){
            api.queryDictDetail('unit').then(res => {
                if(res.code == 200){
                    this.unitList = res.data
                }
            })
        },
        back(){
            this.step -= 1
        },
        next() {
            switch (this.step) {
                case 1:
                    const form1Valid = this.$refs.appInfoForm.validate().then();
                    Promise.all([form1Valid]).then((results) => {
                        // results 是一个包含两个表单校验结果的数组
                        if (results.every(result => result)) {
                            this.step += 1
                        } else {
                            return false;
                        }
                    })
                    break;
                case 2:
                    this.$refs.specInfoForm.validate((valid) => {
                        if (valid) {
                            this.step += 1; // 只有在校验通过时才增加步骤
                        } else {
                            return false;
                        }
                    });
                    break;
                default:
                    break
            }
        },
        validateName(param1) {
            return (rule, value, callback) => {
                if (param1 === undefined ||param1 === '' || param1 === null) {
                    callback(new Error('请填写规格名称'));
                } else {
                    callback();
                }
            };
        },
        validateDeliveryExplain(param1) {
            return (rule, value, callback) => {
                if (param1 === undefined ||param1 === '' || param1 === null) {
                    callback(new Error('请填写规格说明'));
                } else {
                    callback();
                }
            };
        },
        validateDeliveryDays(rule, value, callback) {
            if (this.specInfoForm.deliveryDayType === 1) {
                // 如果 deliveryDayType 为 1，则不校验 deliveryDays
                callback();
            } else {
                // 其他情况下校验 deliveryDays
                if (value === undefined ||value === '' || value === null) {
                    callback(new Error('请填写交付时间'));
                } else {
                    callback();
                }
            }
        },
        addAccount(){
            this.passList.push({ account:'', password:'' })
        },
        addSpecifications(){
            this.specInfoForm.specList.push({ name:'', deliveryExplain:'' })
        },
        uploadFile(file){
            let uid = JSON.parse(window.localStorage.getItem('ecolUserInfo')).id
            let params = new FormData()
            params.append('file', file.file)
            params.append('path', "/ecol/License/" + uid)
            api.fileUpload(params).then(res=>{
                if(res.code == 200){
                    let data = res.data[0]
                    this.appInfoForm.picture = data.path + '/' + data.fileName
                }
            })
        },
        uploadMapFile(file){
            let uid = JSON.parse(window.localStorage.getItem('ecolUserInfo')).id
            let params = new FormData()
            params.append('file', file.file)
            params.append('path', "/ecol/License/" + uid)
            api.fileUpload(params).then(res=>{
                if(res.code == 200){
                    let data = res.data[0]
                    this.appInfoForm.advertisingMap = data.path + '/' + data.fileName
                }
            })
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        handleAvatarMapSuccess(res, file) {
            this.imageMapUrl = URL.createObjectURL(file.raw);
        },
        changeFile(file){
            this.file = file
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        changeMapFile(file){
            this.file = file
            this.imageMapUrl = URL.createObjectURL(file.raw);
        },
        uploadFiles(file){
            let uid = JSON.parse(window.localStorage.getItem('ecolUserInfo')).id
            let params = new FormData()
            params.append('file', file.file)
            params.append('path', "/ecol/License/" + uid)
            api.fileUpload(params).then(res=>{
                if(res.code == 200){
                    let data = res.data[0]
                    this.appInfoForm.documentUrl = data.path + '/' + data.fileName
                }
            })
        },
        changeFileList(file,fileList){
            this.fileList = fileList
        },
        submitForm() {
            const form1Valid = this.$refs.appInfoForm.validate();
            const form2Valid = this.$refs.specInfoForm.validate();
            const form3Valid = this.$refs.afterSalesForm.validate();
            Promise.all([form1Valid, form2Valid, form3Valid]).then((results) => {
                if (results.every(result => result)) {
                    let params = Object.assign({appSpecAddVos: []}, this.appInfoForm, this.afterSalesForm);
                    params.picture = this.appInfoForm.picture
                    params.advertisingMap = this.appInfoForm.advertisingMap
                    params.documentUrl = this.appInfoForm.documentUrl
                    params.companyId = JSON.parse(window.localStorage.getItem('ecolUserInfo')).companyId
                    params.appDetailAddVo.passwords = this.passList
                    this.specInfoForm.specList.forEach(item => {
                        params.appSpecAddVos.push({
                            deliveryDays: this.specInfoForm.deliveryDayType == 0 ? this.specInfoForm.deliveryDays : -1,
                            deliveryExplain: item.deliveryExplain,
                            name: item.name,
                            taxPrice: this.specInfoForm.taxPrice,
                            deliveryUnit: this.specInfoForm.deliveryUnit,
                            deliveryTypes: this.specInfoForm.deliveryTypes,
                            appId: this.specInfoForm.appId,
                            id: this.specInfoForm.id,
                        })
                    })
                    if (this.$route.query.id) {
                        api.modifyApp({id: this.$route.query.id, ...params}).then(res => {
                            this.loading = false
                            if (res.code == 200) {
                                this.cancel()
                                this.$notify({
                                    title: '成 功',
                                    message: res.msg,
                                    type: 'success'
                                });
                            } else {
                                this.$notify({
                                    title: '提 示',
                                    message: res.msg,
                                    type: 'warning'
                                });
                            }
                        })
                            .catch(err => {
                                this.loading = false
                            })
                    } else {
                        api.publishApp(params).then(res => {
                            this.loading = false
                            if (res.code == 200) {
                                this.cancel()
                                this.$notify({
                                    title: '成 功',
                                    message: res.msg,
                                    type: 'success'
                                });
                            } else {
                                this.$notify({
                                    title: '提 示',
                                    message: res.msg,
                                    type: 'warning'
                                });
                            }
                        })
                            .catch(err => {
                                this.loading = false
                            })
                    }
                } else {
                    return false;
                }
            })
        },
        reset(){
            this.specInfoForm = {
                deliveryDayType:0,
                specList:[
                    { name:'', deliveryExplain:'' }
                ],
                deliveryType:[],
                appId:'',
                id:'',
            }
            this.appInfoForm = {
                appDetailAddVo:{},
            },
            this.afterSalesForm = {}
        },
        cancel(){
            this.reset()
            this.$router.back()
        },
    }
}
</script>

<style>

.applicationFormchang{
    padding-top:24px;
}
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
  }
  .applicationForm{
    padding:12px;
    .el-select{
        width:260px;
    }
  }

</style>
